import { motion } from 'framer-motion';
import Reviews from '../components/Reviews';
import Accordion from '../components/Accordion';
import ContactForm from '../components/ContactForm';
import ButtonComponent from '../components/ButtonComponent';
import GalleryMultiStatic from '../components/GalleryMultiStatic';

import { useNavigate } from 'react-router-dom';
import BehindTheScenes from '../components/BehindTheScene';
import LocationServices from '../components/LocationServices';
import MobileHero from '../components/MobileHero';
import UndergroundShelf from '../components/UndergroundShelf';
import NJLSC from '../components/NJLSC';

export default function Home() {
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 120, // Reduced stiffness for a slower animation
        damping: 26, // Slightly increased damping for a smoother slowdown
      },
    },
  };

  return (
    <div className="flex flex-col gap-48 overflow-x-hidden">
      {/* <DetailBubbles /> */}

      {/* <AsbestosInfo /> */}
      <section className="">
        <div className="flex flex-col justify-center items-center w-full py-8 gap-4 px-4">
          {/* <h1 className="font-bold text-5xl md:text-[3rem] xl:text-[5rem]">
            What We Do
          </h1> */}
          {/* <Hero /> */}
          {/* <GalleryMulti /> */}
          <div className="flex flex-col xl:flex-row justify-center items-center">
            <div className="">
              <GalleryMultiStatic />
            </div>
            <div className="xl:w-[70%]">
              <motion.div
                className="hidden xl:flex flex-col gap-4 text-slate-700 font-semibold px-6 md:text-xl"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <img src="/imgs/Abated Main Logo ALT.png" alt="" />
              </motion.div>
              <motion.div
                className="flex flex-col gap-4 text-slate-700 font-semibold px-6 md:text-xl"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <p>
                  Here at Abated, we specialize in{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Asbestos Abatement
                  </span>
                  ,{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Lead Abatement
                  </span>
                  ,{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Lead Inspections
                  </span>
                  ,{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Asbestos Inspections
                  </span>
                  ,{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Demolition
                  </span>{' '}
                  and{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    General Contracting
                  </span>
                  . We are a <span className="">licensed</span> and insured
                  company that is dedicated to providing our customers with the
                  best service possible.
                </p>
                <p>
                  We have completed many projects throughout the New Jersey
                  area. Our team is made up of highly trained professionals who
                  are committed to providing quality workmanship at competitive
                  prices. If you need any help with your project, please contact
                  us today!
                </p>
              </motion.div>
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <div className="w-full flex justify-center items-center">
                  <div className="md:w-[50%] lg:w-[58%]">
                    <ButtonComponent
                      label={'Lets get that quote'}
                      onClick={() => {
                        // naviage to contact form
                        navigate('/services');
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          {/* <div className="flex flex-col xl:flex-row justify-center items-center">
            <div className="hidden lg:block">
              <GalleryMultiStatic />
            </div>
            <div className="block lg:hidden">
              <UndergroundShelf />
              <MobileHero />
            </div>
            <div className="hidden lg:block xl:w-[70%]">
              <UndergroundShelf />
              <motion.div
                className="hidden xl:flex flex-col gap-4 text-slate-700 font-semibold px-6 md:text-xl"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <img src="/imgs/Abated Main Logo ALT.png" alt="" />
              </motion.div>
              <motion.div
                className="flex flex-col gap-4 text-slate-700 font-semibold px-6 md:text-xl"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <p>
                  Here at Abated, we specialize in{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Asbestos Abatement
                  </span>
                  ,{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Lead Abatement
                  </span>
                  ,{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Lead Inspections
                  </span>
                  ,{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Asbestos Inspections
                  </span>
                  ,{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    Demolition
                  </span>{' '}
                  and{' '}
                  <span className="bg-gray-200 rounded-lg px-2">
                    General Contracting
                  </span>
                  . We are a <span className="">licensed</span> and insured
                  company that is dedicated to providing our customers with the
                  best service possible.
                </p>
                <p>
                  We have completed many projects throughout the New Jersey
                  area. Our team is made up of highly trained professionals who
                  are committed to providing quality workmanship at competitive
                  prices. If you need any help with your project, please contact
                  us today!
                </p>
              </motion.div>
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <div className="w-full flex justify-center items-center">
                  <div className="lg:w-[50%] lg:w-[58%]">
                    <ButtonComponent
                      label={'Lets get that quote'}
                      onClick={() => {
                        // naviage to contact form
                        navigate('/services');
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            </div>
          </div> */}
        </div>
      </section>

      <LocationServices />

      {/* img */}
      <BehindTheScenes />

      {/* new component about NJ Lead-Safe Certification Law */}
      <NJLSC />

      <Reviews />

      <div className="flex flex-col justify-center items-center">
        <div className="lg:w-1/2 flex flex-col gap-6">
          <section className="flex flex-col gap-6 w-full">
            <Accordion
              title="Dangers of Asbestos?"
              body={[
                `The dangers of asbestos reach far beyond homes. Asbestos, a natural mineral prized for its fire resistance and insulation properties, has a hidden peril in its microscopic fibers. When disturbed, these fibers can become airborne, posing severe health risks. Workers in industries like construction, shipyards, and factories have been particularly vulnerable to asbestos-related diseases.`,
                `Prolonged inhalation of these tiny, sharp fibers can lead to fatal diseases, including lung cancer, mesothelioma, and asbestosis. While asbestos use has declined, it remains a concern in older buildings, necessitating careful management and removal. Recognizing and addressing asbestos dangers is crucial to protecting the health of individuals, workers, and communities worldwide.`,
              ]}
              borderColor="border-b-primaryAlt"
            />
            <Accordion
              title="Dangers of Lead?"
              body={[
                `Lead exposure is a critical concern, particularly in older homes and products. Lead, once commonly used in paint, pipes, and toys, poses severe health risks when ingested or inhaled. Even small amounts can be harmful, especially to children, affecting their brain development and causing behavioral problems, learning difficulties, and various health issues.`,
                `Adults are not immune to lead's effects, which can include high blood pressure, kidney damage, and reproductive problems. Given its widespread use in the past, understanding and mitigating lead exposure is essential for safeguarding public health.`,
              ]}
              borderColor="border-b-primary"
            />
            <Accordion
              title="Should I be concerned about Asbestos?"
              body={[
                `Homeowners must be aware of the serious risks of asbestos in their homes. Asbestos, once a popular material for its fire resistance and insulation, is now known as a hidden danger. Disturbed asbestos materials during home renovations release microscopic fibers that can lead to lung cancer, asbestosis, and mesothelioma after decades of exposure. `,
                `To protect themselves and their families, homeowners should be cautious with potential asbestos-containing materials and seek professional testing and removal services when in doubt. Ignoring asbestos risks can have devastating consequences.`,
              ]}
              borderColor="border-b-primaryAlt"
            />
            <Accordion
              title="Should I be concerned about Lead?"
              body={[
                `The presence of lead in your environment is a significant health hazard that requires immediate attention, especially in homes built before the 1978 ban on lead-based paint. Children are particularly vulnerable to lead poisoning, which can result from ingesting or inhaling lead dust. This can lead to severe developmental issues, learning disabilities, and other health problems.`,
                `Homeowners should be vigilant in testing for lead, especially before undertaking renovations that could disturb old layers of paint. Professional lead remediation is recommended to ensure a safe living environment. Being proactive about lead is critical to prevent its harmful effects.`,
              ]}
              borderColor="border-b-primary"
            />
          </section>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="lg:w-1/2 flex flex-col gap-6">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

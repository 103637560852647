import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import '../styles/styles.css';

interface AccordionProps {
  title: string;
  body: string[];
  startOpen?: boolean;
  borderColor?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  body,
  startOpen,
  borderColor,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen || false);
  const [openedOnce, setOpenedOnce] = useState<number>(0);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setOpenedOnce(openedOnce + 1);
  };

  return (
    <div className="w-full mx-auto">
      <div
        className={`bg-slate-100 p-2 flex justify-center items-center cursor-pointer rounded-sm border-b-[.15rem] ${
          borderColor ? borderColor : ''
        } ${isOpen ? 'w-full' : ''}`} // Expand to full width when open
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          {' '}
          {/* Center text and emoji */}
          <h2
            className={`text-lg font-semibold ${
              isOpen
                ? 'mx-4'
                : `mx-2 ${openedOnce <= 0 && 'animate-pulse'} duration-1000`
            }`}
          >
            {title}
          </h2>
          <span role="img" aria-label="Note" className="text-lg"></span>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ ease: 'easeOut', duration: 0.3 }}
            className="bg-white p-2 border border-gray-200 font-semibold text-slate-700 px-8 rounded-sm"
          >
            <div>
              {body.map((paragraph, index) => (
                <p key={index} className="pb-2">
                  {paragraph}
                </p>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;

import React from 'react';

const NJLSC = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-8 py-12 bg-slate-100 px-4 iair:px-8 lg:rounded-lg">
      <div className="lg:w-4/5 flex flex-col gap-6">
        <section className="flex flex-col gap-6 w-full">
          <div className="flex flex-col lg:flex-row items-center gap-6">
            <img
              src="/imgs/NJLSC.webp"
              alt="Lead-Safe Certification"
              className="w-full lg:w-1/2 rounded-lg"
            />
            <div className="flex flex-col gap-4">
              <h2 className="text-2xl font-bold text-primary">
                NJ Lead-Safe Certification Law
              </h2>
              <p>
                The New Jersey Lead-Safe Certification Law requires that certain
                contractors and property owners take specific precautions when
                working on homes built before 1978. This law aims to protect
                residents from lead exposure during renovations, repairs, and
                painting projects that could disturb lead-based paint.
              </p>
              <p>
                Contractors and property owners must be certified by the New
                Jersey Department of Community Affairs (DCA) to work on pre-1978
                homes. They must also follow lead-safe work practices to
                minimize lead dust and contamination. By complying with these
                regulations, contractors and property owners can help prevent
                lead poisoning and create safer living environments for
                residents.
              </p>
              <div>
                <a
                  href="https://legiscan.com/NJ/text/S1147/2020"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline hover:text-blue-700"
                >
                  Learn more about the NJ Lead-Safe Certification Law
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <button
        className="bg-primary font-semibold text-white text-md px-3 py-3 m-1 rounded hover:bg-gray-600 transform transition-transform duration-200 active:scale-95"
        onClick={() => (window.location.href = '/booking/lead')}
      >
        Book a Lead Inspection
      </button>
    </div>
  );
};

export default NJLSC;

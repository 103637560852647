import { useState, useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';

function LeadBooking() {
  const [formData, setFormData] = useState({
    address: '',
    blockNumber: '',
    lotNumber: '',
    contactName: '',
    contactPhone: '',
    city: '',
    builtBefore1978: '',
    initialInspection: '',
    unitsToInspect: '',
    contactEmail: '',
    inspectionDate: '',
    inspectionTime: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  //   const [showEasterEgg, setShowEasterEgg] = useState(false);
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: any) => {
    const message = `
      Address: ${formData.address}
      Block Number: ${formData.blockNumber}
      Lot Number: ${formData.lotNumber}
      City: ${formData.city}
      Built Before 1978: ${formData.builtBefore1978}
      Initial Inspection: ${formData.initialInspection}
      Units to Inspect: ${formData.unitsToInspect}
      Inspection Date: ${formData.inspectionDate}
      Inspection Time: ${formData.inspectionTime}
    `;

    const templateParams = {
      name: formData.contactName,
      phone: formData.contactPhone,
      email: formData.contactEmail,
      message: message,
    };

    emailjs.send(
      process.env.REACT_APP_SERVICE_ID!,
      process.env.REACT_APP_TEMPLATE_ID!,
      templateParams,
      process.env.REACT_APP_PUBLIC_KEY!
    );

    setTimeout(() => {
      window.location.reload();
      window.scrollTo(0, 0);
    }, 2000);
  };

  const debouncedSendEmail = debounce(sendEmail, 1000);

  return (
    <motion.div
      className="w-full flex flex-col items-center justify-center px-6 py-8 bg-slate-200/90 rounded-lg shadow-lg backdrop-blur-md"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.p
        className="md:text-5xl text-2xl font-bold leading-7 text-center w-full py-4"
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Lead Inspection
      </motion.p>
      <form
        className="top-40 rounded-sm py-5 w-full px-6"
        ref={form}
        onSubmit={(e) => {
          e.preventDefault();
          setIsSubmitting(true);
          debouncedSendEmail(e);
        }}
      >
        <div className="flex flex-col gap-3 w-full items-center mt-9">
          <motion.div
            className="flex flex-col w-full"
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">
              Address
            </label>
            <input
              type="text"
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              placeholder="Address"
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              required
            />
          </motion.div>
          <motion.div
            className="flex flex-col w-full"
            initial={{ x: -50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">
              Block Number{' '}
              <span className="text-sm text-gray-500">
                (we can help you find this if you don't know)
              </span>
            </label>
            <input
              type="text"
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              placeholder="Block Number"
              onChange={(e) =>
                setFormData({ ...formData, blockNumber: e.target.value })
              }
              required
            />
          </motion.div>
          <motion.div
            className="flex flex-col w-full"
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">
              Lot Number{' '}
              <span className="text-sm text-gray-500">
                (we can help you find this if you don't know)
              </span>
            </label>
            <input
              type="text"
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              placeholder="Lot Number"
              onChange={(e) =>
                setFormData({ ...formData, lotNumber: e.target.value })
              }
              required
            />
          </motion.div>
        </div>

        <div className="flex flex-col gap-3 w-full items-center mt-9">
          <p className="md:text-2xl text-xl font-bold leading-7 text-center w-full py-4">
            Property Information
          </p>
          <motion.div
            className="flex flex-col w-full"
            initial={{ x: -50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">
              City / Town
            </label>
            <input
              type="text"
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              placeholder="City / Town"
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              required
            />
          </motion.div>
          <motion.div
            className="flex flex-col w-full"
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">
              Built Before 1978
            </label>
            <select
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              onChange={(e) =>
                setFormData({ ...formData, builtBefore1978: e.target.value })
              }
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </motion.div>
        </div>

        <div className="flex flex-col gap-3 w-full items-center mt-9">
          <p className="md:text-2xl text-xl font-bold leading-7 text-center w-full py-4">
            Contact Information
          </p>
          <motion.div
            className="flex flex-col w-full"
            initial={{ x: -50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">Name</label>
            <input
              type="text"
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              placeholder="Name"
              onChange={(e) =>
                setFormData({ ...formData, contactName: e.target.value })
              }
              required
            />
          </motion.div>
          <motion.div
            className="flex flex-col w-full"
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">
              Email
            </label>
            <input
              type="email"
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              placeholder="Email"
              onChange={(e) =>
                setFormData({ ...formData, contactEmail: e.target.value })
              }
              required
            />
          </motion.div>
          <motion.div
            className="flex flex-col w-full"
            initial={{ x: -50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">
              Phone
            </label>
            <input
              type="tel"
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              placeholder="Phone"
              onChange={(e) =>
                setFormData({ ...formData, contactPhone: e.target.value })
              }
              required
            />
          </motion.div>

          <motion.div
            className="flex flex-col w-full"
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">
              Inspection Date
            </label>
            <input
              type="date"
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              onChange={(e) =>
                setFormData({ ...formData, inspectionDate: e.target.value })
              }
              required
            />
          </motion.div>
          <motion.div
            className="flex flex-col w-full"
            initial={{ x: -50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <label className="text-base font-semibold leading-none">
              Inspection Time{' '}
              <span className="text-sm text-gray-500">(optional)</span>
            </label>
            <input
              type="time"
              className="text-base leading-none p-3 focus:outline-none focus:border-primary mt-4 bg-white border rounded-sm border-gray-200"
              onChange={(e) =>
                setFormData({ ...formData, inspectionTime: e.target.value })
              }
            />
          </motion.div>
        </div>

        <div className="flex items-center justify-center w-full space-x-3">
          <motion.button
            className={`mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-primary rounded-sm focus:ring-2 focus:ring-offset-2 focus:outline-none shadow ${
              isSubmitted ? 'cursor-default bg-primary-dark' : ''
            }`}
            type="submit"
            disabled={isSubmitted}
            whileTap={{ scale: 0.9 }}
          >
            {isSubmitting ? (
              <motion.span
                className="flex items-center"
                animate={{
                  scale: [1, 1.1, 1],
                  rotate: [0, 0, 360],
                  color: [
                    'rgba(255, 255, 255, 1)',
                    'rgba(255, 255, 255, 0.7)',
                    'rgba(255, 255, 255, 1)',
                  ],
                }}
                transition={{
                  duration: 1,
                  times: [0, 0.5, 1],
                  repeat: Infinity,
                }}
              >
                <svg
                  className="w-5 h-5 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.293 5.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-9 9a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 16.586l4.293-4.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Submitting
              </motion.span>
            ) : (
              'SUBMIT'
            )}
          </motion.button>
        </div>
      </form>
      {/* {showEasterEgg && (
        <motion.div
          className="mt-10 p-4 bg-yellow-300 rounded-lg shadow-lg"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-lg font-bold text-center">
            🎉 You've discovered an easter egg! 🎉
          </p>
        </motion.div>
      )} */}
    </motion.div>
  );
}

export default LeadBooking;

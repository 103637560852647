import React from 'react';
import { motion } from 'framer-motion';
import ServiceCard from './ServiceCard';

export default function LocationServices() {
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  };

  return (
    <motion.section
      className="flex flex-col justify-center items-center w-full px-4 gap-12 bg-slate-100 py-16 lg:rounded-lg"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="flex justify-center items-center">
        <h1 className="font-bold text-center text-4xl md:text-[3rem] xl:text-[5rem] mb-12">
          Services
        </h1>
      </div>
      <div className="flex flex-col gap-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* ServiceCard components */}
          <ServiceCard
            description=""
            title="Lead Abatement"
            imageUrl="/imgs/lead-paint.webp"
          />
          <ServiceCard
            description=""
            title="Asbestos Abatement"
            imageUrl="imgs/general-contracting.webp  "
          />

          <ServiceCard
            title="Expert Services"
            description="Ensure a safe environment with our comprehensive lead removal services. Our EPA-certified professionals conduct thorough inspections and utilize advanced abatement techniques to effectively eliminate lead hazards from your residential or commercial property."
            imageUrl=""
          />
          <ServiceCard
            description=""
            title="Lead Inspections"
            imageUrl="imgs/lead-test.webp"
          />
          <ServiceCard
            title="Removal Services"
            description="Safeguard your New Jersey property from lead hazards with our EPA-certified lead removal services. Our experienced team conducts thorough inspections and employs advanced abatement techniques to ensure a safe environment for all occupants."
            imageUrl=""
            svgPosition="bl"
          />
          {/* ServiceCard components */}
          <ServiceCard
            description=""
            title="Asbestos Inspections"
            imageUrl="/imgs/asbestos-lab.webp"
          />
          <ServiceCard
            description=""
            title="Demolition Services"
            imageUrl="/imgs/demolition-2.webp"
          />
          <ServiceCard
            description=""
            title="General Contracting"
            imageUrl="imgs/general-contracting-2.webp"
          />
          {/* New ServiceCard components */}
          <ServiceCard
            title="Material Inspection"
            description="Protect your New Jersey property from lead and asbestos hazards with our EPA-certified inspection and abatement services. Our experienced professionals conduct thorough assessments and utilize advanced techniques to ensure a safe environment for all occupants."
            imageUrl=""
          />
        </div>
        {/* <ServiceLocations /> */}
      </div>
    </motion.section>
  );
}

import { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import FormInput from './FormInput';
import ToggableDiv from './ToggableDiv';

interface Props {
  showModal?: boolean;
  setShowModal?: Function;
}

function JobApplicationForm(props: Props) {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [messageSkills, setMessageSkills] = useState<string>('');
  const [messageExperience, setMessageExperience] = useState<string>('');

  //   booleans for toggling availability components
  const [sunday, setSunday] = useState<boolean>(false);
  const [monday, setMonday] = useState<boolean>(false);
  const [tuesday, setTuesday] = useState<boolean>(false);
  const [wednesday, setWednesday] = useState<boolean>(false);
  const [thursday, setThursday] = useState<boolean>(false);
  const [friday, setFriday] = useState<boolean>(false);
  const [saturday, setSaturday] = useState<boolean>(false);

  const form = useRef<HTMLFormElement>(null);

  //   const sendEmail = (e: any) => {
  //     e.preventDefault();

  //     const templateParams = {
  //       name: formName,
  //       phone: formPhone,
  //       email: formEmail,
  //       message: formMessage,
  //     };

  //     emailjs.send(
  //       process.env.REACT_APP_SERVICE_ID!,
  //       process.env.REACT_APP_TEMPLATE_ID!,
  //       templateParams,
  //       process.env.REACT_APP_PUBLIC_KEY!
  //     );

  //     form.current?.reset();
  //     props.setShowModal && props.setShowModal(false);
  //   };

  return (
    <div className={`w-full flex items-center justify-center px-4 py-12`}>
      {/* form start */}
      <form
        className={`top-40 rounded py-5 w-full lg:w-[65%] px-6`}
        ref={form}
        // onSubmit={sendEmail}
      >
        {/* header text */}
        <p className="md:text-3xl text-xl font-bold leading-7 text-center w-full">
          Start your career right!
        </p>

        {/* * inputs */}
        <div className="flex flex-col lg:flex-row gap-6 w-full items-center mt-9">
          {/* first name input */}
          <FormInput variableName="First Name" setVariable={setFirstName} />

          {/* last name input */}
          <FormInput variableName="Last Name" setVariable={setLastName} />

          {/* email input */}
          <FormInput variableName="Email" setVariable={setEmail} />

          {/* phone input */}
          <FormInput variableName="Phone" setVariable={setPhone} />
        </div>

        {/* message input */}
        <div>
          {/* work experience */}
          <div className="w-full flex flex-col mt-8">
            <label className="text-base font-semibold leading-none">
              Work Experience
            </label>
            <textarea
              tabIndex={0}
              aria-label="leave a message"
              name="message"
              className="h-20 text-base leading- none p-3 focus:outline-none focus:border-primary mt-4 bg-slate-100 border rounded border-gray-200 text-black resize-none"
              defaultValue={''}
              onChange={(e) => setMessageExperience(e.target.value)}
              required
            />
          </div>

          {/* availability */}
          <div className="w-full flex flex-col mt-8 gap-3">
            <label className="text-base font-semibold leading-none">
              Availability
            </label>

            {/* buttons for each day of the week */}
            <div className="container m-auto grid grid-cols-3 gap-7 text-center">
              <ToggableDiv
                classNames="text-base font-semibold rounded focus:ring-2 focus:ring-offset-2 focus:outline-none active:scale-90 hover:scale-110 transition duration-150 ease-in-out shadow select-none"
                activeColor="bg-primary opacity-100 text-white"
                inactiveColor="bg-white opacity-50"
                text="Sunday"
                active={sunday}
                setActive={setSunday}
              />
              <ToggableDiv
                classNames="text-base font-semibold rounded focus:ring-2 focus:ring-offset-2 focus:outline-none active:scale-90 hover:scale-110 transition duration-150 ease-in-out shadow select-none"
                activeColor="bg-primary opacity-100 text-white"
                inactiveColor="bg-white opacity-50"
                text="Monday"
                active={monday}
                setActive={setMonday}
              />
              <ToggableDiv
                classNames="text-base font-semibold rounded focus:ring-2 focus:ring-offset-2 focus:outline-none active:scale-90 hover:scale-110 transition duration-150 ease-in-out shadow select-none"
                activeColor="bg-primary opacity-100 text-white"
                inactiveColor="bg-white opacity-50"
                text="Tuesday"
                active={tuesday}
                setActive={setTuesday}
              />
              <ToggableDiv
                classNames="text-base font-semibold rounded focus:ring-2 focus:ring-offset-2 focus:outline-none active:scale-90 hover:scale-110 transition duration-150 ease-in-out shadow select-none"
                activeColor="bg-primary opacity-100 text-white"
                inactiveColor="bg-white opacity-50"
                text="Wednesday"
                active={wednesday}
                setActive={setWednesday}
              />
              <ToggableDiv
                classNames="text-base font-semibold rounded focus:ring-2 focus:ring-offset-2 focus:outline-none active:scale-90 hover:scale-110 transition duration-150 ease-in-out shadow select-none"
                activeColor="bg-primary opacity-100 text-white"
                inactiveColor="bg-white opacity-50"
                text="Thursday"
                active={thursday}
                setActive={setThursday}
              />
              <ToggableDiv
                classNames="text-base font-semibold rounded focus:ring-2 focus:ring-offset-2 focus:outline-none active:scale-90 hover:scale-110 transition duration-150 ease-in-out shadow select-none"
                activeColor="bg-primary opacity-100 text-white"
                inactiveColor="bg-white opacity-50"
                text="Friday"
                active={friday}
                setActive={setFriday}
              />
              <ToggableDiv
                classNames="text-base font-semibold rounded focus:ring-2 focus:ring-offset-2 focus:outline-none active:scale-90 hover:scale-110 transition duration-150 ease-in-out shadow select-none"
                activeColor="bg-primary opacity-1 opacity-100 text-white00"
                inactiveColor="bg-white opacity-50"
                text="Saturday"
                active={saturday}
                setActive={setSaturday}
              />
            </div>
          </div>

          {/* skills */}
          <div className="w-full flex flex-col mt-8">
            <label className="text-base font-semibold leading-none">
              Skills
            </label>
            <textarea
              tabIndex={0}
              aria-label="leave a message"
              name="message"
              className="h-20 text-base leading- none p-3 focus:outline-none focus:border-primary mt-4 bg-slate-100 border rounded border-gray-200 text-black resize-none"
              defaultValue={''}
              onChange={(e) => setMessageSkills(e.target.value)}
              required
            />
          </div>
        </div>

        {/* buttons */}
        <div className="flex items-center justify-center w-full space-x-3">
          {props.setShowModal && (
            <button className="mt-9 text-base font-semibold leading-none text-red-500 py-4 px-10 rounded hover:bg-indigo-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none">
              CANCEL
            </button>
          )}

          <motion.div
            className="mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-primary rounded focus:ring-2 focus:ring-offset-2 focus:outline-none shadow active:scale-90 hover:scale-110 transition duration-150 ease-in-out opacity-30 select-none"
            // type="submit"
          >
            Apply
          </motion.div>
        </div>
      </form>
    </div>
  );
}

export default JobApplicationForm;
